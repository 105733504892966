import { gql } from '@apollo/client'
import { FragmentItemOutput } from '../../fragment/itemOutput'
import { FragmentItemConsumption } from '../../fragment/itemConsumption'
import { FragmentJobInventoryPutAway } from '../../fragment/jobInventoryPutAway'

export const WORKTASK_BY_ID_WITH_TASK_RESULTS = gql`
  query WorkTaskByIdWithTaskResults($where: [OptionsWhere], $filter: FilterWhere) {
    nworkTask(where: $where, filter: $filter) {
      id
      name
      cosmosKey
      order
      status
      statusOrder
      types
      taskOptions
      workItem {
        id
        cosmosKey
        number
        itemDescription
        item {
          id
          name
        }
        phaseCode
        plannedQuantity
        unitOfMeasure
        actualQuantity
        jobs {
          id
          code
          name
        }
        plannedPositions {
          id
          code
          name
        }
        activity {
          id
          name
          code
        }
        comment {
          id
          text
        }
        trackingIdStatusChange {
          id
        }
        itemConsumption(frontend: true) {
          ...FragmentItemConsumption
        }
        itemOutput {
          ...FragmentItemOutput
        }
        jobInventoryConsumption {
          id
        }
        jobInventoryOutput {
          id
        }
        itemInventoryPicking {
          id
        }
        jobInventoryPicking {
          id
        }
        jobInventoryPutAway(frontend: true) {
          ...FragmentJobInventoryPutAway
        }
      }
    }
  }

  ${FragmentItemOutput}
  ${FragmentItemConsumption}
  ${FragmentJobInventoryPutAway}
`
