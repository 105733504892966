import { useTranslation } from 'react-i18next'
import { MDialog, MDialogContentText } from '../../mprise-light/Dialog'

export const ConfirmCancelDialog = ({
  open,
  onClose,
  onConfirm,
  contentText,
}: {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  contentText: string
}) => {
  const { t } = useTranslation()

  return (
    <MDialog open={open} title={t('CANCEL')} onSubmit={onConfirm} onClose={onClose}>
      <MDialogContentText>{contentText}</MDialogContentText>
    </MDialog>
  )
}
