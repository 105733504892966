import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { FetchResult, useApolloClient, useMutation } from '@apollo/client'
import { Formik, MAudio, ValidationIssues, withFormikCompareFix } from '@mprise/react-ui'
import { TransferCarrierCodesForm } from './TransferCarriersForm'
import { useHistory } from '../../../shared/use-history'
import { FlashAlertApi, FlashAlerts } from '../../../shared/flash-alerts'
import { useAppSettingsContext } from '../../../context/AppSettingsContext'
import { useLocalState } from '../../../shared/local-state'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { SavingSwitchPanel } from '../../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../../shared/apollo'
import { fail } from '../../../shared/typescript'
import { ScannedJobInventory } from '../../../shared/interfaces'
import { i18n } from '../../../i18n/instance'
import { REPORT_TRANSFER_CARRIERS_TO_POSITION } from '../../../gql/mutation/reportTransferCarriersToPosition'
import { REPORT_TRANSFER_AND_OFFLOAD_CARRIERS_TO_POSITION } from '../../../gql/mutation/reportTransferAndOffloadCarriersToPosition'
import { STOP_TASK } from '../../../gql/mutation/statusChange/statusChange'
import { Maybe, WorkItemTemplateTaskOption } from '../../../shared/enums'
import { parseFloatQuantity } from '../../../shared/formats'
import { STOP_TIME_REG } from '../../../gql/mutation/timeReg'
import { useState } from 'react'
import { FormikContextType } from 'formik'
import { ConfirmCancelDialog } from '../../../shared/dialog/ConfirmCancelDialog'

export interface TransferCarrierCodesEntryForm {
  carrierCode: Maybe<{ id: string; name: string; code: Maybe<string> }>
  position: Maybe<{ id: string; name: string; code: Maybe<string> }>
  scannedJobInventories: Maybe<ScannedJobInventory[]>
  jobId: Maybe<string>
  isWorkItemTransfer: boolean
  workItemId: Maybe<string>
  taskOptions: Maybe<WorkItemTemplateTaskOption[]>
  userSelectedStayOnCarriers: boolean
  userSelectedOffloadCarriers: boolean
  workItemCosmosKey: Maybe<string>
  taskCosmosKey: Maybe<string>
}

export const TransferCarriersRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()
  const apollo = useApolloClient()
  const cache = apollo.cache

  const schema = TransferCarrierCodesForm.useSchema()

  const { resource, numberFormat } = useAppSettingsContext()
  const resourceId = resource?.id ?? h.push('/')
  const [showCancelConfirmDialog, setShowCancelConfirmDialog] = useState<boolean>(false)

  const [stopTaskMutation, stopTaskState] = useMutation(STOP_TASK)
  const [stopTimeReg] = useMutation(STOP_TIME_REG)

  // If Transfer is used by a redirect of a WorkOrder: Prefill form
  const workItemId = useParams().workItemId
  const taskId = useParams().taskId
  // If phaseCode is defined: do an automatic phase transition at transfer
  const phaseCodeText = useParams().phaseCode
  const phaseCode = phaseCodeText === 'null' ? null : phaseCodeText

  const [initialValues] = useLocalState((): TransferCarrierCodesEntryForm => {
    return withFormikCompareFix({
      carrierCode: { id: '', name: '', code: '' },
      position: { id: '', name: '', code: '' },
      scannedJobInventories: null,
      jobId: null,
      isWorkItemTransfer: workItemId ? true : false,
      workItemId: workItemId ?? null,
      taskOptions: null,
      userSelectedStayOnCarriers: false,
      userSelectedOffloadCarriers: false,
      workItemCosmosKey: null,
      taskCosmosKey: null,
    })
  }, [])

  const handleClose = async (e: React.FormEvent<Element>, fc?: FormikContextType<TransferCarrierCodesEntryForm>) => {
    e.preventDefault()
    e.stopPropagation()

    if (fc?.values.scannedJobInventories?.length) {
      setShowCancelConfirmDialog(true)
    } else {
      if (workItemId && taskId) {
        await stopTaskMutation({
          variables: {
            workItemId: +workItemId,
            taskId: +taskId,
            currentResourceId: +resourceId,
          },
        }).then(response => {
          const task = response.data.stopTask
          stopTimeReg({
            variables: {
              workItemId: task.workItem.cosmosKey,
              taskId: task.cosmosKey,
            },
          })
        })
      }
      h.goBack()
    }
  }

  const handleCloseConfirm = async (autoFinished?: boolean) => {
    if (workItemId && taskId && !autoFinished) {
      await stopTaskMutation({
        variables: {
          workItemId: +workItemId,
          taskId: +taskId,
          currentResourceId: +resourceId,
        },
      }).then(response => {
        const task = response.data.stopTask
        stopTimeReg({
          variables: {
            workItemId: task.workItem.cosmosKey,
            taskId: task.cosmosKey,
          },
        })
      })
    }
    setShowCancelConfirmDialog(false)
    h.goBack()
  }

  const handleSubmit = (
    form: TransferCarrierCodesEntryForm,
    actions: Formik.FormikHelpers<TransferCarrierCodesEntryForm>,
  ) => {
    actions.resetForm()

    if (
      form.userSelectedStayOnCarriers ||
      form.taskOptions?.includes(WorkItemTemplateTaskOption.MoveCarriersToLocationGh)
    ) {
      handleSubmitTransferCarriers(form)
    } else if (
      form.userSelectedOffloadCarriers ||
      form.taskOptions?.includes(WorkItemTemplateTaskOption.OffloadCarriersGh)
    ) {
      handleSubmitTransferAndOffloadCarriers(form)
    }
  }

  const [reportTransferCarriersToPosition, reportTransferCarriersToPositionState] = useMutation(
    REPORT_TRANSFER_CARRIERS_TO_POSITION,
  )

  const [reportTransferAndOffloadCarriersToPosition, reportTransferAndOffloadCarriersToPositionState] = useMutation(
    REPORT_TRANSFER_AND_OFFLOAD_CARRIERS_TO_POSITION,
  )

  const handleSubmitTransferCarriers = async (form: TransferCarrierCodesEntryForm) => {
    const positionId = form.position!.id
    const scannedJobInventories = form.scannedJobInventories
    if (!resourceId) {
      fail('expects resource id')
    }
    let autoFinished = false

    const jobInventoryIdsAndQuantities =
      scannedJobInventories?.map(entry => ({
        jobInventoryDetailId: +entry.jidId,
        quantity: parseFloatQuantity(entry.quantity, numberFormat),
      })) ?? []
    await reportTransferCarriersToPosition({
      variables: {
        jobInventoryIdsAndQuantities: jobInventoryIdsAndQuantities,
        destinationPositionId: +positionId,
        resourceId: +resourceId,
        destinationPhase: phaseCode,
        workItemId: workItemId ? +workItemId : null,
      },
    }).then(response => {
      if (response.data) {
        showSuccesTransferCarriers(response, jobInventoryIdsAndQuantities.length, alerts)
        autoFinished = response.data.transferCarriersToPositionGH.autoFinished
        if (autoFinished) {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'searchWorkItems' })
        }
      }
    })

    if (workItemId && taskId) {
      if (form.workItemCosmosKey && form.taskCosmosKey && autoFinished) {
        stopTimeReg({
          variables: {
            workItemId: form.workItemCosmosKey,
            taskId: form.taskCosmosKey,
          },
        })
      }
      handleCloseConfirm(autoFinished)
    }
  }

  const handleSubmitTransferAndOffloadCarriers = async (form: TransferCarrierCodesEntryForm) => {
    const positionId = form.position!.id
    const scannedJobInventories = form.scannedJobInventories

    if (!resourceId) {
      fail('expects resource id')
    }
    let autoFinished = false

    const jobInventoryIdsAndQuantities =
      scannedJobInventories?.map(entry => ({
        jobInventoryDetailId: +entry.jidId,
        quantity: parseFloatQuantity(entry.quantity, numberFormat),
      })) ?? []
    await reportTransferAndOffloadCarriersToPosition({
      variables: {
        jobInventoryIdsAndQuantities: jobInventoryIdsAndQuantities,
        destinationPositionId: +positionId,
        resourceId: +resourceId,
        destinationPhase: phaseCode ?? null,
        workItemId: workItemId ? +workItemId : null,
      },
    }).then(response => {
      if (response.data) {
        showSuccessTransferAndOffloadCarriers(response, jobInventoryIdsAndQuantities.length, alerts)
        autoFinished = response.data.transferAndOffloadCarriersToPositionGH.autoFinished
        if (autoFinished) {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'searchWorkItems' })
        }
      }
    })

    if (workItemId && taskId) {
      if (form.workItemCosmosKey && form.taskCosmosKey && autoFinished) {
        stopTimeReg({
          variables: {
            workItemId: form.workItemCosmosKey,
            taskId: form.taskCosmosKey,
          },
        })
      }
      handleCloseConfirm(autoFinished)
    }
  }

  return (
    <>
      <Helmet title={t('TRANSFER_CARRIER_CODES')} />

      <Formik.Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <DialogFormik
          minWidth='xl'
          title={t('TRANSFER_CARRIER_CODES')}
          onClose={handleClose}
          onCloseWithContext={handleClose}
          open={true}
        >
          <SavingSwitchPanel
            mutation={[
              reportTransferCarriersToPositionState,
              reportTransferAndOffloadCarriersToPositionState,
              stopTaskState,
            ]}
          >
            <ValidationIssues />
            <MutationErrorMessage
              mutation={[
                reportTransferCarriersToPositionState,
                reportTransferAndOffloadCarriersToPositionState,
                stopTaskState,
              ]}
            />
            <TransferCarrierCodesForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
      <ConfirmCancelDialog
        open={showCancelConfirmDialog}
        onClose={() => {
          setShowCancelConfirmDialog(false)
        }}
        onConfirm={handleCloseConfirm}
        contentText={t('QUESTION_CONFIRM_DATA_WILL_BE_LOST')}
      />
    </>
  )
}

export function showSuccessTransferAndOffloadCarriers(
  result: FetchResult<any>,
  totalAmount: number,
  alerts: FlashAlertApi,
) {
  MAudio.scanSuccess()
  const successCount = result.data!.transferAndOffloadCarriersToPositionGH?.succeeded?.length
  alerts.push(i18n.t('X_OUT_OF_Y_SUCCESSFULLY_POSTED', { x: successCount, y: totalAmount }), 'success')
}

export function showSuccesTransferCarriers(result: FetchResult<any>, totalAmount: number, alerts: FlashAlertApi) {
  MAudio.scanSuccess()
  const successCount = result.data!.transferCarriersToPositionGH?.succeeded?.length
  alerts.push(i18n.t('X_OUT_OF_Y_SUCCESSFULLY_POSTED', { x: successCount, y: totalAmount }), 'success')
}
