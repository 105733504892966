import { TextField, TextFieldProps } from '@mui/material'
import { useMField } from '@mprise/react-ui'

export const MFieldTextField = (props: Partial<TextFieldProps>) => {
  const f = useMField()

  return (
    <TextField
      id={f.id ?? undefined}
      name={f.name ?? undefined}
      value={f.value}
      onChange={e => f.onChange?.(e.target.value, e)}
      multiline
      fullWidth
      minRows={15}
      variant='standard'
      {...props}
    />
  )
}
