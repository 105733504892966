import { gql } from '@apollo/client'

export const FragmentItemOutput = gql`
  fragment FragmentItemOutput on WorkItemOutput {
    id
    taskId
    fulfilled
    planned {
      id
      job {
        id
        code
        name
      }
      position {
        id
        code
        name
      }
      resource {
        id
        code
        name
      }
      trackingIds {
        id
        code
        unitOfMeasure
        status
        warehouse {
          id
          status {
            id
            name
          }
        }
      }
      item {
        id
        code
        name
      }
      itemDescription
      itemNumber
      lotNumber
      quantity
      quantityUnit
      trackingIdRequired
      outputPosition {
        id
        code
        name
      }
      unitOfMeasure
      qtyPerContainer
      allowOverpick
      variantCode
      warehouseTrackingCode
    }
    reported {
      id
      quantity
    }
  }
`
