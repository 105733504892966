import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { MenuRoute } from './routes/Menu'
import { SettingsRoute } from './routes/settings/Home'
import { SettingsLanguageRoute } from './routes/settings/Language'
import { SettingsScanningRoute } from './routes/settings/Scanning'
import { SettingsCompanyRoute } from './routes/settings/Company'
import { TransferRoute } from './routes/transfer/transfer-job/Home'
import { ScrapRoute } from './routes/scrap/Home'
import { PhaseRoute } from './routes/phase/Home'
import { AppSettingsContainer } from './context/AppSettingsContext'
import { SettingsResourceRoute } from './routes/settings/Resource'
import './App.css'
import { TasksRoute } from './routes/tasks/Home'
import { WorkItemDetailsRoute } from './routes/tasks/WorkItemDetails'
import { TransferMenuRoute } from './routes/transfer/TransferMenu'
import { TransferCarriersRoute } from './routes/transfer/transfer-carriers/Home'
import { JobRoute } from './routes/job/Home'
import { UploadCarriersRoute } from './routes/transfer/upload-carriers/Home'
import { CreateInventoryRoute } from './routes/tasks/create-inventory/Home'
import { JobPickRoute } from './routes/tasks/job-pick/Home'
import { SettingsNumberFormatRoute } from './routes/settings/NumberFormat'
import { AreaRegistrationRoute } from './routes/area-registration/Home'
import { ErrorBoundary } from './ErrorBoundary'
import { CropMaintenanceRoute } from './routes/tasks/crop-maintenance/Home'
import { SortAndTransferRoute } from './routes/tasks/sort-and-transfer/Home'
import { JobMutationsRoute } from './routes/job-mutations/Home'
import { SettingsDefaultNumberInputRoute } from './routes/settings/DefaultNumberInput'
import { FeatureFlagsContainer } from './context/FeatureFlagsContext'

export const App = () => {
  return (
    <AppSettingsContainer>
      <FeatureFlagsContainer>
        <RouterProvider router={router} />
      </FeatureFlagsContainer>
    </AppSettingsContainer>
  )
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />}>
      <Route path='/settings/language' element={<SettingsLanguageRoute />} />
      <Route path='/settings/company' element={<SettingsCompanyRoute />} />
      <Route path='/settings/scanning' element={<SettingsScanningRoute />} />
      <Route path='/settings/resource' element={<SettingsResourceRoute />} />
      <Route path='/settings/numberFormat' element={<SettingsNumberFormatRoute />} />
      <Route path='/settings/defaultNumberInput' element={<SettingsDefaultNumberInputRoute />} />
      <Route path='/settings' element={<SettingsRoute />} />
      <Route path='/tasks/:tab?' element={<TasksRoute />} />
      <Route path='/tasks/details/:id' element={<WorkItemDetailsRoute />} />
      <Route path='/job' element={<JobRoute />} />
      <Route path='/job-mutations' element={<JobMutationsRoute />} />
      <Route path='/job-mutations/:workItemId/:taskId/:phaseCode' element={<JobMutationsRoute />} />
      <Route path='/transfer' element={<TransferMenuRoute />} />
      <Route path='/transfer/job' element={<TransferRoute />} />
      <Route path='/transfer/job/:workItemId/:taskId/:phaseCode' element={<TransferRoute />} />
      <Route path='/transfer/carriercodes' element={<TransferCarriersRoute />} />
      <Route path='/transfer/carriercodes/:workItemId/:taskId/:phaseCode' element={<TransferCarriersRoute />} />
      <Route path='/transfer/uploadcarriers' element={<UploadCarriersRoute />} />
      <Route path='/transfer/uploadcarriers/:workItemId/:taskId/:phaseCode' element={<UploadCarriersRoute />} />
      <Route path='/transfer/create-inventory/:workItemId/:taskId' element={<CreateInventoryRoute />} />
      <Route path='/transfer/job-pick/:workItemId/:taskId/:phaseCode' element={<JobPickRoute />} />
      <Route path='/area-registration' element={<AreaRegistrationRoute />} />
      <Route path='/area-registration/:jobCode' element={<AreaRegistrationRoute />} />
      <Route path='/crop-maintenance/:workItemId/:taskId' element={<CropMaintenanceRoute />} />
      <Route path='/sort-and-transfer/:taskId' element={<SortAndTransferRoute />} />
      <Route path='/scrap' element={<ScrapRoute />} />
      <Route path='/phase' element={<PhaseRoute />} />
      <Route path='/' element={<MenuRoute />} />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Route>,
  ),
)
