import { useEffect, useRef, useState } from 'react'
import { Formik, MAudio, MFieldConnector, MFlexBlock, Yup } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { ScannedUploadCarrier } from '../../../shared/interfaces'
import { Section } from '../../../components/Section'
import { nameof } from '../../../shared/typescript'
import { FieldCarrierCode } from '../../../shared/form/FieldCarrierCode'
import { FieldPosition } from '../../../shared/form/FieldPosition'
import { FieldJob } from '../../../shared/form/FieldJob'
import { FieldJobInventoryDetail } from '../../../shared/form/FieldJobInventoryDetail'
import { UploadCarriersEntryForm } from './Home'
import { ScannedUploadCarriersSection } from './ScannedUploadCarriersSection'
import { useParams } from 'react-router-dom'
import { SelectedJobInventoryDetails } from '../../../shared/form/selectionDetails'
import { TaskDetailsSection } from '../../../shared/form/TaskDetailsSection'
import { i18n } from '../../../i18n/instance'
import { useLazyQuery, useQuery } from '@apollo/client'
import { WORKTASK_BY_ID_WITH_TASK_RESULTS } from '../../../gql/query/workItems/workTaskByIdWithTaskResults'
import { OUTBOUND_BY_WORKITEM_ID } from '../../../gql/query/outboundByWorkItemId'
import { parseFloatQuantity } from '../../../shared/formats'
import { useAppSettingsContext } from '../../../context/AppSettingsContext'
import { Maybe } from '../../../shared/enums'
import { FieldSelectionQuantity } from '../../../shared/form/SelectionFieldQuantity'

export const UploadCarriersForm = () => {
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()
  const fc = Formik.useFormikContext<UploadCarriersEntryForm>()
  const carrierCode = fc.values.carrierCode
  const taskId = useParams().taskId ?? ''
  const workItemId = fc.values.workItemId

  const [scanned, setScanned] = useState<ScannedUploadCarrier[]>([])
  const [totalQuantity, setTotalQuantity] = useState<Maybe<number>>(null)

  const taskQuery = useQuery(WORKTASK_BY_ID_WITH_TASK_RESULTS, {
    variables: {
      where: [{ field: 'id', options: { eq: +taskId } }],
      filter: { mandatoryType: 'JOB_WORK_ORDER' },
    },
  })

  const task = taskQuery.data?.nworkTask
  const taskUnitOfMeasure = fc.values.jobInventoryDetail?.unitOfMeasure
  const [getPreviousScanned, previousScannedQuery] = useLazyQuery(OUTBOUND_BY_WORKITEM_ID, {
    fetchPolicy: `network-only`,
  })

  useEffect(() => {
    // Prefill form if WorkItemTask is executed
    const job = (task?.workItem.jobs ?? [])[0]
    const position = (task?.workItem.jobInventoryPutAway ?? [])[0]?.planned.position

    if (task && job && position) {
      fc.setValues(current => ({
        ...current,
        job: { id: job.id, code: job.code!, name: job.name! },
        toPosition: { id: position.id, code: position.code!, name: position.name! },
        workItemCosmosKey: task.workItem?.cosmosKey,
        taskCosmosKey: task.cosmosKey,
      }))
    }
  }, [taskQuery])

  useEffect(() => {
    if (workItemId) {
      getPreviousScanned({
        variables: {
          workItemId: +workItemId,
        },
      })
    }
  }, [])

  // carrierCodes that have already been posted in outbound of this workItem
  const previousScanned = workItemId ? previousScannedQuery.data?.outboundJobInventoryByWorkItemId ?? [] : []

  /** Add scanned carriers to state */
  useEffect(() => {
    if (carrierCode && carrierCode.code && carrierCode.code.length > 0) {
      if (!fc.values.carrierQuantity || fc.values.carrierQuantity < 0) {
        alerts.push(i18n.t('FILL_IN_CARRIER_QTY_ABOVE_0'), 'error')
        MAudio.scanError()
      } else {
        addToScannnedState({
          carrierCode: fc.values.carrierCode?.code ?? '',
          quantity: fc.values.carrierQuantity,
          unitOfMeasure: fc.values.jobInventoryDetail?.unitOfMeasure!,
          timestamp: Date.now(),
        })
      }
    }
  }, [carrierCode])

  const addToScannnedState = async (input: ScannedUploadCarrier) => {
    const isAlreadyScanned = scanned.some(entry => entry.carrierCode === input.carrierCode)
    const isScannedPreviously = previousScanned?.some(
      (entry: { carrierCode: string }) => entry?.carrierCode === input.carrierCode,
    )

    if (isAlreadyScanned || isScannedPreviously) {
      MAudio.scanError()
      alerts.push(i18n.t('CARRIER_ALREADY_SCANNED', { carrier: input.carrierCode }), 'error')
    } else {
      MAudio.scanSuccess()
      alerts.push(i18n.t('CARRIER_SCANNED', { carrier: input.carrierCode }), 'success')

      fc.setValues({
        ...fc.values,
        carrierCode: { id: '', name: '', code: '' },
        scannedCarriers: [...(fc.values.scannedCarriers ?? []), input],
      })
    }
  }

  /** Update scanned state when form updates */
  useEffect(() => {
    setScanned(fc.values.scannedCarriers?.sort((a, b) => b.timestamp - a.timestamp) ?? [])
  }, [fc.values.scannedCarriers])
  const { numberFormat } = useAppSettingsContext()
  /** Update total quantity state when scanned state updates */
  useEffect(() => {
    const sumQuantity = scanned.map(x => parseFloatQuantity(x.quantity, numberFormat)).reduce((x, y) => x + y, 0)
    setTotalQuantity(sumQuantity)
  }, [scanned])

  /** Set focus to the field when the form opens */
  const jobFieldRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    jobFieldRef?.current?.focus()
  }, [jobFieldRef])

  return (
    <>
      {task && <TaskDetailsSection task={task} />}
      <Section>
        <MFlexBlock vertical padding={2}>
          <Formik.Field component={MFieldConnector} name={nameof<UploadCarriersEntryForm>('job')}>
            <FieldJob title={t('ASSIGN_JOB')} ref={jobFieldRef} />
          </Formik.Field>
          <Formik.Field component={MFieldConnector} name={nameof<UploadCarriersEntryForm>('fromPosition')}>
            <FieldPosition
              title={t('ASSIGN_POSITION')}
              label={`FROM_POSITION`}
              neverDisable
              shouldFilterOrigin={false}
            />
          </Formik.Field>
          <div style={{ display: 'none' }}>
            <Formik.Field component={MFieldConnector} name={nameof<UploadCarriersEntryForm>('jobInventoryDetail')}>
              <FieldJobInventoryDetail title={t('ASSIGN_JOB_INVENTORY_DETAIL')} />
            </Formik.Field>
          </div>
          <Formik.Field component={MFieldConnector} name={nameof<UploadCarriersEntryForm>('toPosition')}>
            <FieldPosition title={t('ASSIGN_POSITION')} label={`TO_POSITION`} neverDisable shouldFilterOrigin={false} />
          </Formik.Field>
        </MFlexBlock>
      </Section>

      <Section>
        <SelectedJobInventoryDetails jobInventoryDetail={fc.values.jobInventoryDetail} showPosition={false} />
      </Section>

      <Section>
        <MFlexBlock vertical padding={2}>
          <Formik.Field
            style={{}}
            component={MFieldConnector}
            name={nameof<UploadCarriersEntryForm>(t(`carrierQuantity`))}
          >
            <FieldSelectionQuantity unitOfMeasure={taskUnitOfMeasure} title={t('CARRIER_QUANTITY')} />
          </Formik.Field>
          <Formik.Field component={MFieldConnector} name={nameof<UploadCarriersEntryForm>('carrierCode')}>
            <FieldCarrierCode title={t('ASSIGN_CARRIER_CODE')} />
          </Formik.Field>
        </MFlexBlock>
      </Section>

      {scanned.length > 0 && <ScannedUploadCarriersSection scanned={scanned} totalQuantity={totalQuantity} />}
    </>
  )
}

UploadCarriersForm.useSchema = () => {
  const { t } = useTranslation()

  // Transfer to same position is allowed.

  const [schema] = useState(
    (): Yup.SchemaOf<UploadCarriersEntryForm> =>
      Yup.object().shape({
        job: Yup.object()
          .shape({
            id: Yup.string().required('Job is a required field'),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .required('Job is a required field')
          .nullable()
          .label(t(`JOB`)),
        fromPosition: Yup.object()
          .shape({
            id: Yup.string().nullable(),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .nullable(),
        jobInventoryDetail: Yup.mixed().required().label(t(`JOB_INVENTORY_DETAIL`)),
        toPosition: Yup.object()
          .shape({
            id: Yup.string().required('Position is a required field'),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .required()
          .nullable()
          .label(t(`POSITION`)),
        carrierCode: Yup.mixed()
          .optional()
          .nullable()
          .label(t(`JOB`))
          .test('carrierCode', 'No carriers scanned', async (input: any, context: any) => {
            return !!context.parent.scannedCarriers?.length
          }),
        carrierQuantity: Yup.mixed().nullable().optional().label('carrierQuantity'),
        scannedCarriers: Yup.mixed().optional().nullable(),
        isWorkItemTransfer: Yup.boolean().required(),
        workItemId: Yup.mixed().optional().nullable(),
        workItemCosmosKey: Yup.mixed().optional().nullable(),
        taskCosmosKey: Yup.mixed().optional().nullable(),
      }),
  )
  return schema
}
