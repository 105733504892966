import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Formik, MFieldConnector } from '@mprise/react-ui'
import { DialogFormik } from '../../mprise-light/DialogFormik'
import { MFieldTextField } from '../../mprise-light/MFieldTextField'
import { Section } from '../../components/Section'
import { CHANGE_COMMENT } from '../../gql/mutation/changeComment'

interface FormValues {
  workItemId: number
  text: string
}

export const CommentDialog = ({
  open,
  initialValues,
  closeDialog,
}: {
  open: boolean
  initialValues: FormValues
  closeDialog: () => void
}) => {
  const { t } = useTranslation()

  const [changeComment] = useMutation(CHANGE_COMMENT)

  const handleSubmit = (form: FormValues, actions: Formik.FormikHelpers<FormValues>) => {
    if (form.workItemId && form.text !== initialValues.text) {
      changeComment({
        variables: {
          workItemId: form.workItemId,
          text: form.text,
        },
      })
    }
    actions.resetForm()
    closeDialog()
  }

  return (
    <Formik.Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
      <DialogFormik open={open} title={t('COMMENT')} minWidth='xl'>
        <Section>
          <Formik.Field component={MFieldConnector} name='text'>
            <MFieldTextField
              inputRef={input => setTimeout(() => input?.focus(), 0)}
              onFocus={event => event.target.setSelectionRange(-1, -1)}
            />
          </Formik.Field>
        </Section>
      </DialogFormik>
    </Formik.Formik>
  )
}
