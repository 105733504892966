import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_BOOLEAN_SETTING } from '../gql/settings'
import { useAppSettingsContext } from './AppSettingsContext'

export const FeatureFlagsContainer = ({ children }: { children: React.ReactNode }) => {
  const [enableWorkOrderComments, setEnableWorkOrderComments] = useState<boolean>(false)

  const { company } = useAppSettingsContext()

  const [getEnableCommentsFeature] = useLazyQuery(GET_BOOLEAN_SETTING, {
    onError: console.error,
  })

  useEffect(() => {
    if (company?.id) {
      getEnableCommentsFeature({
        variables: {
          companyId: +company.id,
          settingName: 'enable-work-order-comments',
        },
      }).then(result => {
        setEnableWorkOrderComments(result.data.booleanSetting)
      })
    }
  }, [company, getEnableCommentsFeature])

  return (
    <FeatureFlagsContext.Provider
      value={{
        enableWorkOrderComments,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  )
}

export const useFeatureFlagsContext = () => {
  const context = useContext(FeatureFlagsContext)
  if (!context) {
    throw new Error('Context called without a Provider.')
  }
  return context
}

const FeatureFlagsContext = createContext<
  | {
      enableWorkOrderComments: boolean
    }
  | undefined
>(undefined)
