import React, { useState } from 'react'
import { Formik, MAudio, withFormikCompareFix } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { useHistory } from '../../../shared/use-history'
import { useLocalState } from '../../../shared/local-state'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { SavingSwitchPanel } from '../../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../../shared/apollo'
import { ValidationIssues } from '../../../mprise-light/ValidationIssues'
import { useAppSettingsContext } from '../../../context/AppSettingsContext'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import { JobPickForm } from './JobPickForm'
import { ConfirmCancelDialog } from '../../../shared/dialog/ConfirmCancelDialog'
import { fail } from '../../../shared/typescript'
import { JobInventoryDetail, ScannedJobInventory } from '../../../shared/interfaces'
import { FormikContextType } from 'formik'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { WORKTASK_BY_ID_WITH_TASK_RESULTS } from '../../../gql/query/workItems/workTaskByIdWithTaskResults'
import { STOP_TASK } from '../../../gql/mutation/statusChange/statusChange'
import { REPORT_ITEM_OUTPUT } from '../../../gql/mutation/reportItemOutput'
import { BULK_REDUCE_JOB_INVENTORY } from '../../../gql/mutation/bulkReduceJobInventory'
import { Maybe } from '../../../shared/enums'
import { parseFloatQuantity } from '../../../shared/formats'
import { STOP_TIME_REG } from '../../../gql/mutation/timeReg'

export interface JobPickEntryForm {
  job: Maybe<{ id: string; name: string; code: Maybe<string>; item?: Maybe<{ id: string; name?: Maybe<string> }> }>
  position: Maybe<{ id: string; name: string; code: Maybe<string> }>
  jobInventoryDetail: Maybe<JobInventoryDetail>
  originalQuantity: Maybe<number>
  quantity: Maybe<number>
  carrierCode: Maybe<{ id: string; name: string; code: Maybe<string> }>
  scannedJobInventories: Maybe<ScannedJobInventory[]>
}

export const JobPickRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const apollo = useApolloClient()
  const cache = apollo.cache

  const schema = JobPickForm.useSchema()
  const alerts = FlashAlerts.useAlert()
  const { resource, numberFormat } = useAppSettingsContext()
  const resourceId = resource?.id ?? h.push('/')
  const [showCancelConfirmDialog, setShowCancelConfirmDialog] = useState<boolean>(false)

  const workItemId = useParams().workItemId!
  const taskId = useParams().taskId!

  const taskQuery = useQuery(WORKTASK_BY_ID_WITH_TASK_RESULTS, {
    variables: {
      where: [{ field: 'id', options: { eq: +taskId } }],
      filter: { mandatoryType: 'JOB_PICK_ORDER' },
    },
  })
  const task = taskQuery.data?.nworkTask
  const workItemCosmosKey = task?.workItem.cosmosKey
  const taskCosmosKey = task?.cosmosKey

  const [initialValues] = useLocalState((): JobPickEntryForm => {
    return withFormikCompareFix({
      job: { id: '', name: '', code: '' },
      position: { id: '', name: '', code: '' },
      jobInventoryDetail: null,
      originalQuantity: null,
      quantity: null,
      carrierCode: { id: '', name: '', code: '' },
      scannedJobInventories: null,
    })
  }, [])

  const [stopTaskMutation, stopTaskState] = useMutation(STOP_TASK, {
    refetchQueries: [WORKTASK_BY_ID_WITH_TASK_RESULTS],
  })

  const [stopTimeReg] = useMutation(STOP_TIME_REG)

  const [reportItemOutput, reportItemOutputState] = useMutation(REPORT_ITEM_OUTPUT)
  const [bulkReduceJobInventory, bulkReduceJobInventoryState] = useMutation(BULK_REDUCE_JOB_INVENTORY)

  const handleClose = async (e: React.FormEvent<Element>, fc?: FormikContextType<JobPickEntryForm>) => {
    e.preventDefault()
    e.stopPropagation()

    // If carriers are in cache, confirm if user wants to cancel
    if (fc?.values.scannedJobInventories?.length) {
      setShowCancelConfirmDialog(true)
    } else {
      await stopTaskMutation({
        variables: {
          workItemId: +workItemId,
          taskId: +taskId,
          currentResourceId: +resourceId,
        },
      }).then(response => {
        const task = response.data.stopTask
        stopTimeReg({
          variables: {
            workItemId: task.workItem.cosmosKey,
            taskId: task.cosmosKey,
          },
        })
      })
      h.goBack()
    }
  }

  const handleCloseConfirm = async () => {
    if (workItemId && taskId) {
      await stopTaskMutation({
        variables: {
          workItemId: +workItemId,
          taskId: +taskId,
          currentResourceId: +resourceId,
        },
      }).then(response => {
        const task = response.data.stopTask
        stopTimeReg({
          variables: {
            workItemId: task.workItem.cosmosKey,
            taskId: task.cosmosKey,
          },
        })
      })
    }
    setShowCancelConfirmDialog(false)
    h.goBack()
  }

  const handleSubmit = async (form: JobPickEntryForm) => {
    if (!resourceId) {
      fail('expects resource id')
    }

    if (!task) {
      return
    }

    let success = true

    const scanned = form.scannedJobInventories ?? []
    const reported = scanned.map(entry => ({
      jidId: entry.jidId,
      jobId: entry.jobId,
      quantity: entry.quantity,
      originPositionId: entry.positionId,
      carrierCode: entry.carrierCode,
    }))

    if (form.jobInventoryDetail && form.quantity) {
      // add manual input to reported (scanned)
      const manualItemOutput = {
        jidId: form.jobInventoryDetail.id,
        jobId: form.jobInventoryDetail.job.id,
        quantity: form.quantity,
        originPositionId: form.jobInventoryDetail.position.id,
        carrierCode: form.carrierCode?.code ?? '',
      }
      reported.push(manualItemOutput)
    }

    const destinationPositionId = form.position!.id
    const succeededIO: string[] = []
    let autoFinished = false

    if (reported.length > 0) {
      for (const report of reported) {
        const quantity = parseFloatQuantity(report.quantity, numberFormat)

        await reportItemOutput({
          variables: {
            workItemId: workItemId,
            taskId: task.id,
            itemOutputId: +task.workItem.itemOutput![0]!.id,
            values: {
              jobId: +report.jobId,
              resourceId: +resourceId,
              quantity: quantity,
              positionId: +report.originPositionId,
              outputPositionId: +destinationPositionId,
              trackingIds: [],
              carrierCode: report.carrierCode,
            },
          },
        }).then(response => {
          autoFinished = response.data.registerItemOutputNoTrid.autoFinished
          if (autoFinished) {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'searchWorkItems' })
          }
          succeededIO.push(report.jidId)
          success &&= !!response.data
        })
      }

      const jobInventoryIdsAndQuantities = reported
        .filter(report => succeededIO.includes(report.jidId))
        .map(report => {
          return {
            jobInventoryDetailId: report.jidId,
            quantity: parseFloatQuantity(report.quantity, numberFormat),
          }
        })

      const result = await bulkReduceJobInventory({
        variables: {
          jobInventoryIdsAndQuantities: jobInventoryIdsAndQuantities,
        },
      })
      success &&= !!result.data
    }

    if (success) {
      MAudio.scanSuccess()
      alerts.push(t('SUCCESS_MESSAGE'), `success`)
    }

    if (autoFinished && workItemCosmosKey && taskCosmosKey) {
      await stopTimeReg({
        variables: {
          workItemId: workItemCosmosKey,
          taskId: taskCosmosKey,
        },
      })
      h.goBack()
    } else {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'nworkItem' })
      handleCloseConfirm()
    }
  }

  return (
    <>
      <Helmet title={t('JOB_PICK')} />
      <Formik.Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <DialogFormik
          minWidth='xl'
          title={t('JOB_PICK')}
          onClose={handleClose}
          onCloseWithContext={handleClose}
          open={true}
        >
          <SavingSwitchPanel mutation={[reportItemOutputState, bulkReduceJobInventoryState, stopTaskState]}>
            <ValidationIssues />
            <MutationErrorMessage mutation={[reportItemOutputState, bulkReduceJobInventoryState, stopTaskState]} />
            <JobPickForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
      <ConfirmCancelDialog
        open={showCancelConfirmDialog}
        onClose={() => {
          setShowCancelConfirmDialog(false)
        }}
        onConfirm={handleCloseConfirm}
        contentText={t('QUESTION_CONFIRM_SCANNED_WILL_BE_REMOVED')}
      />
    </>
  )
}
