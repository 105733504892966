import { gql } from '@apollo/client'

export const CHANGE_COMMENT = gql`
  mutation changeComment($workItemId: Int!, $text: String!) {
    changeComment(workItemId: $workItemId, text: $text) {
      id
      comment {
        text
      }
    }
  }
`
