import React, { ForwardedRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApolloClient } from '@apollo/client'
import { Formik, MAudio, useMField } from '@mprise/react-ui'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { makeQueryCallback } from '../apollo'
import { SelectJobDialog } from '../dialog/SelectJobDialog'
import { FlashAlerts } from '../flash-alerts'
import { fail } from '../typescript'
import { FieldBarcode } from '../../mprise-light/FieldBarcode'
import { TransferEntryForm } from '../../routes/transfer/transfer-job/Home'
import { useHistory } from '../use-history'
import { IncorrentItemDialog } from '../dialog/IncorrectItemDialog'
import { JOB_BY_CODE } from '../../gql/query/jobs/jobByCode'
import { Maybe } from '../enums'

export type FieldJobValue = {
  id: string
  name?: Maybe<string>
  code?: Maybe<string>
  status?: Maybe<string>
  item?: Maybe<{ id: string; name?: Maybe<string> }>
  outputItem?: Maybe<{ id: string; name?: Maybe<string> }>
}

export const FieldJob = React.forwardRef(
  (
    {
      title,
      itemConstraint,
    }: {
      title: string
      itemConstraint?: Maybe<{ id: string; name?: Maybe<string> }>
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const h = useHistory()
    const { t } = useTranslation()
    const f = useMField()
    const fc = Formik.useFormikContext<TransferEntryForm>()
    const { company: currentCompany, scanSetting } = useAppSettingsContext()
    const companyId = currentCompany?.id ?? h.push('/')
    const apollo = useApolloClient()
    const alerts = FlashAlerts.useAlert()
    const [open, setOpen] = useState(false)
    const [showIncorrectItemDialog, setShowIncorrectItemDialog] = useState<{
      expected?: Maybe<string>
      scanned?: Maybe<string>
    } | null>(null)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const scanDialogTitle = t('SCAN_JOB_CODE')

    return (
      <>
        {showIncorrectItemDialog && (
          <IncorrentItemDialog
            expectedItem={showIncorrectItemDialog.expected}
            scannedItem={showIncorrectItemDialog.scanned}
            onClose={() => setShowIncorrectItemDialog(null)}
          />
        )}
        {open && (
          <SelectJobDialog
            open={open}
            onClose={handleClose}
            onSave={(job: FieldJobValue) => {
              if (itemConstraint && (job.outputItem?.id ?? job.item?.id) !== itemConstraint.id) {
                handleInvalidItem(job)
                setOpen(false)
                return
              }
              if (fc.values.isWorkItemTransfer) {
                f.onChange?.(job)
              } else {
                // Empty form values when selected job manually changes
                resetForm(fc, job)
              }
              setOpen(false)
            }}
            title={title}
            itemConstraint={itemConstraint}
          />
        )}
        <FieldBarcode<FieldJobValue>
          title={t(`JOB`)}
          scanningSetting={scanSetting}
          disabled={false}
          valueAsText={x => x.code ?? t(`PLACEHOLDER_NOT_SET`)}
          onLookup={async text => {
            if (!companyId) {
              fail('expects company id')
            }

            const job = await fetchJobByCode(
              {
                filter: {
                  companyId: +companyId,
                  code: text,
                  applyDataPermissions: true,
                },
              },
              apollo,
            )
            if (job && itemConstraint && (job.outputItem?.id ?? job.item?.id) !== itemConstraint.id) {
              handleInvalidItem(job)
              return job
            }
            if (job && !fc.values.isWorkItemTransfer) {
              // Empty form values when selected job manually changes
              resetForm(fc, job)
            }
            return job
          }}
          onError={() => {
            MAudio.scanError()
          }}
          onSuccess={(text, jobItem) => {
            if (!text) {
              return
            }
            if (!jobItem) {
              MAudio.scanError()
              alerts.push(t('NOTIFICATION_NOT_FOUND_OR_NO_PERMISSIONS', { type: t('JOB') }), 'error')
            } else {
              if (jobItem.status !== 'In production' && jobItem.status !== 'In productie') {
                MAudio.scanError()
                alerts.push(t('NOTIFICATION_JOB_NOT_IN_PRODUCTION'), 'error')
              } else {
                MAudio.scanSuccess()
              }
            }
          }}
          onExplore={handleOpen}
          scanDialogTitle={scanDialogTitle}
          ref={ref}
        />
      </>
    )
    function handleInvalidItem(jobItem: Maybe<FieldJobValue>) {
      MAudio.scanError()
      setShowIncorrectItemDialog({
        expected: itemConstraint?.name,
        scanned: jobItem?.outputItem?.name ?? jobItem?.item?.name,
      })
    }
  },
)

const fetchJobByCode = makeQueryCallback<any, any>(JOB_BY_CODE)(x => x.job ?? null)

function resetForm(fc: Formik.FormikContextType<TransferEntryForm>, job: FieldJobValue) {
  fc.setValues(current => ({
    ...current,
    job: { id: job.id ?? '', name: job.name ?? '', code: job.code ?? '' },
    fromPosition: { id: '', name: '', code: '' },
    jobInventoryDetail: null,
    selectedJobInventories: [],
    sortingEntries: [],
    originalQuantity: 0,
  }))
}
