import React from 'react'
import { Caption } from './Caption'
import { CounterBadge } from './CounterBadge'
import { MaterialIcon } from './Icon'
import { RouterLink } from '../shared/router-link'

export const Section = ({ children }: { children?: React.ReactNode }) => {
  return <Section.Container>{children}</Section.Container>
}
Section.Container = ({ children }: { children?: React.ReactNode }) => (
  <section data-cy='mlSectionTestAttribute' className={`ml-section`}>{children}</section>
)

export const SectionList = ({ children }: { children?: React.ReactNode }) => {
  return <SectionList.Container>{children}</SectionList.Container>
}
SectionList.Container = ({ children }: { children?: React.ReactNode }) => (
  <section className={`ml-section-list`}>{children}</section>
)

export const MenuSection = ({ to, iconName, title }: { to: string; iconName: string; title: string }) => {
  return (
    <Section>
      <RouterLink to={to}>
        <Caption
          icon={
            <CounterBadge count={null}>
              <MaterialIcon value={iconName} style={{ fontSize: `3rem` }} />
            </CounterBadge>
          }
        >
          {title}
        </Caption>
      </RouterLink>
    </Section>
  )
}
